import React from 'react';
import emailjs from 'emailjs-com';

export default class EmailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: ''
        };
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleNameChange(event) {
        this.setState({name: event.target.value});
    }
  
    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }
  
    handleMessageChange(event) {
        this.setState({message: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        alert('Name ' + this.state.name + '\nEmail ' + this.state.email + '\nMessage ' + this.state.message);

        emailjs.init(process.env.EMAIL_JS_API_TOKEN);
        emailjs.send(process.env.EMAIL_JS_SERVICE_ID, process.env.EMAIL_JS_SERVICE_ID)
            .then((result) => {
                console.log(result.text + ' - email sent');
            }, (error) => {
                console.log(error.text);
            });
    }
  
    render() {
        return (
            <div className='contact'>
                <form onSubmit={this.handleSubmit}>
                    <div className='form-group form-item'>
                        <input  className='form-control' 
                                name='name' 
                                value={this.state.name} 
                                onChange={this.handleNameChange} 
                                placeholder='Name'>
                        </input>
                    </div>
                    <div className='form-group form-item'>
                        <input  className='form-control' 
                                name='email'
                                value={this.state.email} 
                                onChange={this.handleEmailChange} 
                                placeholder='Email'>
                        </input>
                    </div>
                    <div className='form-group form-item'>
                        <textarea   className='form-control' 
                                    name='message' 
                                    value={this.state.message} 
                                    onChange={this.handleMessageChange} 
                                    placeholder='Message'
                                    rows='5'>
                        </textarea>
                    </div>
                    <button id='btn' 
                            type='submit' 
                            className='btn btn-link shadow-none'>
                        SUBMIT
                    </button>
                </form>
            </div>
        );
    }
};
